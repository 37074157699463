import { template as template_9bd4713b48c740b3b97ec1815399e18d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PasskeyLoginButton = template_9bd4713b48c740b3b97ec1815399e18d(`
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PasskeyLoginButton;
