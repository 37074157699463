import { template as template_076a72cbf6184f82a9d8d3d1f09a10ee } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_076a72cbf6184f82a9d8d3d1f09a10ee(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
