import { template as template_3cea54dc59b14ba3a4932ac041a8f8d6 } from "@ember/template-compiler";
const FKLabel = template_3cea54dc59b14ba3a4932ac041a8f8d6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
