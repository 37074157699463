import { template as template_2d3e8c3a11b543dda0fbea61f1770dac } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { i18n } from "discourse-i18n";
export default class TimeGap extends Component {
    get description() {
        const daysSince = this.args.daysSince;
        if (daysSince < 30) {
            return i18n("dates.later.x_days", {
                count: daysSince
            });
        } else if (daysSince < 365) {
            const gapMonths = Math.round(daysSince / 30);
            return i18n("dates.later.x_months", {
                count: gapMonths
            });
        } else {
            const gapYears = Math.round(daysSince / 365);
            return i18n("dates.later.x_years", {
                count: gapYears
            });
        }
    }
    static{
        template_2d3e8c3a11b543dda0fbea61f1770dac(`
    <div class="topic-avatar"></div>
    <div class="small-action-desc timegap">
      {{this.description}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
