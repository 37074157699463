import { template as template_cdf8894eb0434dc184a6f56165c215d2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cdf8894eb0434dc184a6f56165c215d2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
