import { template as template_502e8e829dd345948252784d6f5d6992 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_502e8e829dd345948252784d6f5d6992(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
